import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import { Auth0Plugin } from "./auth";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '../public/css/normalize.css';
import '../public/css/webflow.css';
import '../public/css/the-agent-nest.webflow.scss';
import '../public/css/portal.css'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import Vuex from 'vuex'
import { AuthConfig } from "./configs";

const { domain, clientId } = AuthConfig;
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  store,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});


Vue.use(Vuex);
//Main data store


Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MMM DD");
  }
});
Vue.mixin({
  methods: {
    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    resizePhoto(url, size)
    {
      var photoId =  url.substring(26);
      var param = "resize=height:"+size+"/pjpg=quality:90/";
      var link = url.substring(0,26);
      return link+param+"/"+photoId;
    },
    copyText(element) {
      var range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
      document.execCommand("copy");

      //Unselect the text
      if (window.getSelection) {window.getSelection().removeAllRanges();}
      else if (document.selection) {document.selection.empty();}
    }

  }
})
const scriptLoader = {
  loaded: [],
  load (src) {
      if (this.loaded.indexOf(src) !== -1) {
          return
      }

      this.loaded.push(src)

      if (document) {
          const script = document.createElement('script')
          script.setAttribute('src', src)
          document.head.appendChild(script)
      }
  }
}

Vue.use({
  install () {
      Vue.prototype.$scriptLoader = scriptLoader
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
