import { MutationTypes } from './types/mutation-types';

export const mutations = {
  [MutationTypes.SET_TEAM](state, team) {
    state.currentTeam = team;
  },
  [MutationTypes.SET_USER](state, currentUser) {
    state.currentUser = currentUser;
  },

  [MutationTypes.SET_CURRENT_TEAM](state, team) {
    state.currentTeam = team;
  },
  [MutationTypes.SET_CURRENT_USER](state, currentUser) {
    state.currentUser = currentUser;
  },

  [MutationTypes.SET_TOKEN](state, token) {
    state.token = token;
  },
};
