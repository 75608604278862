import { AuthConfig } from '../configs';
import { MutationTypes } from './types/mutation-types';
import { ActionTypes } from './types/action-types';

export const actions = {
  [ActionTypes.USER_LOGIN]({ commit }, user) {
    return new Promise((resolve, reject) => {

      //TODO: Authenticate user and set token
    });
  },

  [ActionTypes.USER_LOGOUT]({ commit }) {
    commit(MutationTypes.SET_TEAM, undefined);
    commit(MutationTypes.SET_USER, undefined);
    sessionStorage.removeItem(AuthConfig.TOKEN_STORAGE_KEY);
  },
};
