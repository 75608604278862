import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { authGuard } from "../auth/guard";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: "/invitation",
    name: "invitation",
    component: () => import("../views/invitation.vue")
  },
  {
    path: "/design-tips",
    name: "design",
    component: () => import("../views/SocialMedia/DesignTips.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/quotes",
    name: "quotes",
    component: () => import("../views/SocialMedia/Quotes.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/humor",
    name: "humor",
    component: () => import("../views/SocialMedia/Humor.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/misc",
    name: "misc",
    component: () => import("../views/SocialMedia/Misc.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/holidays",
    name: "holidays",
    component: () => import("../views/SocialMedia/Holidays.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/home-tips",
    name: "homeTips",
    component: () => import("../views/SocialMedia/HomeTips.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/recipes",
    name: "recipes",
    component: () => import("../views/SocialMedia/Recipes.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/vocab",
    name: "vocab",
    component: () => import("../views/SocialMedia/Vocab.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/conversation-starters",
    name: "ConversationStarters",
    component: () => import("../views/SocialMedia/ConversationStarters.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/myth-or-fact",
    name: "MythOrFact",
    component: () => import("../views/SocialMedia/MythOrFact.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/home-tips",
    name: "HomeTips",
    component: () => import("../views/SocialMedia/HomeTips.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/mortgage",
    name: "Mortgage",
    component: () => import("../views/SocialMedia/Mortgage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/canadian",
    name: "Canadian",
    component: () => import("../views/SocialMedia/Canadian.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/local-love",
    name: "localLove",
    component: () => import("../views/SocialMedia/LocalLove.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/infographics",
    name: "infographics",
    component: () => import("../views/SocialMedia/Infographics.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/buyer-posts",
    name: "buyerPosts",
    component: () => import("../views/SocialMedia/BuyerPosts.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/seller-posts",
    name: "sellerPosts",
    component: () => import("../views/SocialMedia/SellerPosts.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/this-or-that",
    name: "ThisOrThat",
    component: () => import("../views/SocialMedia/ThisOrThat.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/covid-19",
    name: "Covid19",
    component: () => import("../views/SocialMedia/Covid-19.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/fill-in-the-blank",
    name: "FillInTheBlank",
    component: () => import("../views/SocialMedia/FillInTheBlank.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/videos",
    name: "videos",
    component: () => import("../views/Videos.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/video-scripts",
    name: "videoScripts",
    component: () => import("../views/Extras/VideoScripts.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/listing-videos",
    name: "ListingVideos",
    component: () => import("../views/Extras/ListingVideos.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/test-s",
    name: "ShareButton",
    component: () => import("../views/Extras/ShareButton.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () => import("../views/Extras/Blogs.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/resources/listing-presentation",
    name: "ListingPresentation",
    component: () => import("../views/Resources/ListingPresentations.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/refer-a-friend",
    name: "refer-a-friend",
    component: () => import("../views/Resources/ReferAFriend.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/Resources/Support.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/webinars",
    name: "Webinars",
    component: () => import("../views/Resources/Webinars.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/augie",
    name: "signup",
    component: () => import("../views/SignUp.vue")
  },
  {
    path: "/listing-generator",
    name: "listingGenerator",
    component: () => import("../views/AI/ListingGenerator.vue")
  },
  {
    path: "/signup",
    name: "checkout",
    component: () => import("../views/SignUp.vue")
  },
  {
    path: "/stripe-checkout",
    name: "StripeCheckout",
    component: () => import("../views/Membership/StripeCheckout.vue")
  },
  {
    path: "/membership/past-due",
    name: "PastDue",
    component: () => import("../views/Membership/PastDue.vue")
  },
  {
    path: "/checkout",
    name: "StripePricingTable",
    component: () => import("../views/Membership/StripePricingTable.vue")
  },
  {
    path: "/checkout-old",
    name: "checkoutOld",
    component: () => import("../views/Membership/CheckoutOld.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Membership/Profile.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Membership/Profile.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/Membership/Team.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/members",
    name: "Members",
    component: () => import("../views/Membership/Members.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/billing",
    name: "Billing",
    component: () => import("../views/Membership/Billing.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/scheduled/getting-started",
    name: "ScheduledGettingStarted",
    component: () => import("../views/Scheduled/GettingStarted.vue")
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    component: () => import("../views/Membership/Upgrade.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/Membership/Checkout.vue"),
  },
  {
    path: "/membership/welcome",
    name: "Welcome",
    component: () => import("../views/Membership/Welcome.vue"),
  },
  {
    path: "/membership/almost-there",
    name: "EmailConfirmation",
    component: () => import("../views/Membership/EmailConfirmation.vue"),
  },
  {
    path: "/ad-of-the-month",
    name: "AdOfTheMonth",
    component: () => import("../views/AdOfMonth.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/listings",
    name: "ListingTemplates",
    component: () => import("../views/Templates/Listings.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/open-house",
    name: "OpenHouseTemplates",
    component: () => import("../views/Templates/OpenHouse.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/agent",
    name: "AgentTemplates",
    component: () => import("../views/Templates/Agent.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/just-sold",
    name: "JustSoldTemplates",
    component: () => import("../views/Templates/JustSold.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/for-rent",
    name: "ForRentTemplates",
    component: () => import("../views/Templates/ForRent.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/testimonials",
    name: "TestimonialsTemplates",
    component: () => import("../views/Templates/Testimonials.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/cover-photos",
    name: "CoverPhotos",
    component: () => import("../views/Templates/CoverPhotos.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/under-contract",
    name: "UnderContract",
    component: () => import("../views/Templates/UnderContract.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/resources/stock-photos",
    name: "StockPhotos",
    component: () => import("../views/Resources/StockPhotos.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/printables/listing-presentations",
    name: "ListingPresentations",
    component: () => import("../views/Printables/ListingPresentations.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/printables/postcards",
    name: "Postcards",
    component: () => import("../views/Printables/Postcards.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/printables/flyers",
    name: "Flyers",
    component: () => import("../views/Printables/Flyers.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/printables/open-house-promos",
    name: "Open House Promos",
    component: () => import("../views/Printables/OpenHousePromos.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/extras/email-signatures",
    name: "EmailSignatures",
    component: () => import("../views/Extras/EmailSignatures.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/extras/highlight-covers",
    name: "HighlightCovers",
    component: () => import("../views/Extras/HighlightCovers.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/extras/instagram-stories",
    name: "InstagramStories",
    component: () => import("../views/Extras/InstagramStories.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/extras/logos",
    name: "Logos",
    component: () => import("../views/Extras/Logos.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/printables/business-cards",
    name: "BusinessCards",
    component: () => import("../views/Printables/BusinessCards.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/printables/success-kits",
    name: "SuccessKits",
    component: () => import("../views/Printables/SuccessKits.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/market-updates",
    name: "MarketUpdates",
    component: () => import("../views/Templates/MarketUpdates.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/templates/coming-soon",
    name: "ComingSoon",
    component: () => import("../views/Templates/ComingSoon.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Membership/logout.vue"),
  },
  {
    path: "/free-day",
    name: "FreeDay",
    component: () => import("../views/FreeDay.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
