import { getInstance } from "./index";

export const authGuard = (to, from, next) => {

  const authService = getInstance();

  const fn = () => {

    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
   
      if (to.name.toLowerCase() == "billing" || to.name.toLowerCase()=="price") {

        return next();
     
      }
      authService.getIdTokenClaims().then(function (result) {
        var billingStatus =
          result["https://theagentnest.com/billbilling_status"];
        if (billingStatus == "current" || billingStatus == "trialing" || billingStatus == "active") {
          return next();
        }
        else if (billingStatus == "cancelled" || billingStatus == "canceled") {
          if (result["https://theagentnest.com/period_end"]) {
            var periodEnd = result["https://theagentnest.com/period_end"];
            if (periodEnd) {
              if (new Date(periodEnd) > new Date(Date.now())) {
                return next();
              }
            }
          }
          next({
            path: "/price",
            name: "StripePricingTable",
            component: () => import("../views/Membership/StripePricingTable.vue")
          });
        }
        else if(billingStatus=="past_due")
          {
            next({
              path: "/membership/past-due",
              name: "PastDue",
              component: () => import("../views/Membership/PastDue.vue")
            });
          }
        else {
 
          console.log("Unauthorized!");
          next({
            path: "/price",
            name: "StripePricingTable",
            component: () => import("../views/Membership/StripePricingTable.vue")
          });
        }
      });

      return next();
    }
  
    if(authService.loginWithRedirect() == "error")
    {
      next({
        path: "/signup",
        name: "checkout",
        component: () => import("../views/SignUp.vue")
      });
    }
    if (to.name == "signup" || to.name == "checkout") {
      let uri = window.location.href.split("?");
      var email = "";
      if (uri.length == 2) {
        let vars = uri[1].split("&");
        let getVars = {};
        let tmp = "";
        vars.forEach(function (v) {
          tmp = v.split("=");
          if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
        });
        console.log(getVars);
        email = getVars.email;
      }
      authService.loginWithRedirect({ action: 'signup', login_hint: email });
    } else {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
