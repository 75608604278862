export const GlobalConfig = {
  appUrl: process.env.VUE_APP_URL,
  appApiUrl: process.env.VUE_APP_API_URL,
  appName: process.env.VUE_APP_NAME,
  appVersion: process.env.VUE_APP_VERSION,
  appBaseUrl: process.env.VUE_APP_BASE_URL,
  appTitle: process.env.VUE_APP_TITLE,
  appLogo: process.env.VUE_APP_LOGO,
  appCompanyName: process.env.VUE_APP_COMPANY_NAME,
  appWhiteLabel: process.env.VUE_APP_WHITE_LABEL,
  appSupportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
  appScheduledPosting: process.env.VUE_APP_SCHEDULED_POSTING,
  appCanvaApiKey: process.env.VUE_APP_CANVA_API_KEY,
  appFooterLogo: process.env.VUE_APP_FOOTER_LOGO,
  appLocation: process.env.VUE_APP_LOCATION,
  appServiceBotId: process.env.VUE_APP_SERVICEBOT_ID,
};

export const AuthConfig = {
  clientId: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
  domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN,
};

export const PaymentConfig = {
  appStripeKey: process.env.VUE_APP_STRIPE_KEY,
  appStripePlanSchedule: process.env.VUE_APP_STRIPE_PLAN_SCHEDULE,
  appStripePlanMonthly: process.env.VUE_APP_STRIPE_PLAN_MONTHLY,
};
