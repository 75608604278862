<template>
  <div class="navigation-container">
    <div style="max-width: 1500px" class="mx-auto px-5">
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        class="navbar w-nav"
      >
        <router-link to="/" class="w-nav-brand">
          <img
            v-bind:src="logo"
            v-bind:style="whiteLabel ? 'max-width: 80%;' : 'max-width: 136%;'"
            width="400"
            alt="agent nest logo"
          />
        </router-link>
        <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
          <div class="nav-links nav-links-1" id="nav-links-desktop">
            <router-link to="/" class="nav-link w-nav-link">
              <i class="far fa-calendar-alt"></i> Calendar
            </router-link>
            <a
              v-if="scheduledPosting"
              :href="scheduledLink"
              class="nav-link w-nav-link"
            >
             <i class="far fa-clock"></i>
              Schedule
            </a>

            <!-- Internal Link (Vue Router Link) -->
            <router-link
              :to="scheduledLink"
              v-else-if="showSchedule"
              class="nav-link w-nav-link"
            >
              <i class="far fa-clock"></i>
              Schedule
            </router-link>
            <div data-hover="1" class="nav-dropdown w-dropdown" role="menu">
              <div
                class="nav-link w-dropdown-toggle"
                @mouseover="smactive = true"
                @mouseleave="smactive = false"
                style="outline: none"
              >
                <div class="row no-wrap">
                  <div>Social Media</div>
                  <i class="fas fa-caret-down dropdown-caret"></i>
                </div>
              </div>
              <nav
                v-show="smactive"
                class="dropdown-list w-dropdown-list"
                id="w-dropdown-toggle-0"
                style="margin-top: -5px"
              >
                <div
                  class="dropdown-menu"
                  @mouseover="smactive = true"
                  @mouseleave="smactive = false"
                >
                  <a href="/home-tips" class="dropdown-link w-dropdown-link"
                    >Home Tips</a
                  >
                  <router-link
                    to="/quotes"
                    class="dropdown-link w-dropdown-link"
                    >Quotes</router-link
                  >
                  <router-link to="/humor" class="dropdown-link w-dropdown-link"
                    >Humor</router-link
                  >
                  <router-link to="/misc" class="dropdown-link w-dropdown-link"
                    >Misc</router-link
                  >
                  <router-link
                    to="/holidays"
                    class="dropdown-link w-dropdown-link"
                    >Holidays</router-link
                  >
                  <router-link
                    to="/fill-in-the-blank"
                    class="dropdown-link w-dropdown-link"
                    >Fill In The Blank</router-link
                  >
                  <router-link
                    to="/recipes"
                    class="dropdown-link w-dropdown-link"
                    >Recipes</router-link
                  >
                  <router-link
                    to="/infographics"
                    class="dropdown-link w-dropdown-link"
                    >Infographics</router-link
                  >
                  <router-link
                    to="/buyer-posts"
                    class="dropdown-link w-dropdown-link"
                    >Buyer Posts</router-link
                  >
                  <router-link
                    to="/seller-posts"
                    class="dropdown-link w-dropdown-link"
                    >Seller Posts</router-link
                  >
                  <router-link
                    to="/this-or-that"
                    class="dropdown-link w-dropdown-link"
                    >This Or That</router-link
                  >
                  <router-link
                    to="/local-love"
                    class="dropdown-link w-dropdown-link"
                    >Local Love</router-link
                  >
                  <router-link to="/design-tips" class="dropdown-link w-dropdown-link"
                    >Design Tips</router-link
                  >
                  <a href="/vocab" class="dropdown-link w-dropdown-link"
                    >Vocab</a
                  >
                  <a
                    href="/conversation-starters"
                    class="dropdown-link w-dropdown-link"
                    >Conversation Starters</a
                  >
                </div>
              </nav>
            </div>
            <div data-hover="1" class="nav-dropdown w-dropdown" role="menu">
              <div
                class="nav-link w-dropdown-toggle"
                @mouseover="etemplates = true"
                @mouseleave="etemplates = false"
                style="outline: none"
              >
                <div class="row no-wrap">
                  <div>Templates</div>
                  <i class="fas fa-caret-down dropdown-caret"></i>
                </div>
              </div>
              <nav
                v-show="etemplates"
                class="dropdown-list w-dropdown-list"
                id="w-dropdown-toggle-0"
                style="margin-top: -5px"
              >
                <div
                  class="dropdown-menu"
                  @mouseover="etemplates = true"
                  @mouseleave="etemplates = false"
                >
                  <router-link
                    to="/templates/listings"
                    class="dropdown-link w-dropdown-link"
                    >Listings</router-link
                  >
                  <router-link
                    to="/templates/open-house"
                    class="dropdown-link w-dropdown-link"
                    >Open House</router-link
                  >
                  <router-link
                    to="/templates/agent"
                    class="dropdown-link w-dropdown-link"
                    >Agent Templates</router-link
                  >
                  <router-link
                    to="/templates/just-sold"
                    class="dropdown-link w-dropdown-link"
                    >Just Sold</router-link
                  >
                  <router-link
                    to="/templates/for-rent"
                    class="dropdown-link w-dropdown-link"
                    >For Rent</router-link
                  >
                  <router-link
                    to="/templates/cover-photos"
                    class="dropdown-link w-dropdown-link"
                    >Cover Photos</router-link
                  >
                  <router-link
                    to="/templates/under-contract"
                    class="dropdown-link w-dropdown-link"
                    >Under Contract</router-link
                  >
                  <router-link
                    to="/templates/testimonials"
                    class="dropdown-link w-dropdown-link"
                    >Testimonials</router-link
                  >
                  <router-link
                    to="/templates/market-updates"
                    class="dropdown-link w-dropdown-link"
                    >Market Updates</router-link
                  >
                  <router-link
                    to="/templates/coming-soon"
                    class="dropdown-link w-dropdown-link"
                    >Coming Soon</router-link
                  >
                </div>
              </nav>
            </div>
            <div
              data-delay="0"
              data-hover="1"
              class="nav-dropdown w-dropdown"
              role="menu"
            >
              <div
                class="nav-link w-dropdown-toggle"
                @mouseover="pactive = true"
                @mouseleave="pactive = false"
                style="outline: none"
              >
                <div class="row no-wrap">
                  <div>Printables</div>
                  <i class="fas fa-caret-down dropdown-caret"></i>
                </div>
              </div>
              <nav
                v-show="pactive"
                class="dropdown-list shadow w-dropdown-list"
                id="w-dropdown-toggle-1"
                style="margin-top: -5px"
              >
                <div
                  class="dropdown-grid"
                  @mouseover="pactive = true"
                  @mouseleave="pactive = false"
                >
                  <!-- <router-link
                    to="/printables/listing-presentations"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Listing Presentations</h6>
                    <div class="text-small">Get that listing.</div>
                  </router-link> -->
                  <router-link
                    to="/printables/postcards"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Postcards</h6>
                    <div class="text-small">So many choices.</div>
                  </router-link>
                  <router-link
                    to="/printables/flyers"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Flyers</h6>
                    <div class="text-small">Download and Print.</div>
                  </router-link>
                  <router-link
                    to="/printables/business-cards"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Business Cards</h6>
                  </router-link>
                  <router-link
                    to="/printables/open-house-promos"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Open House Promos</h6>
                  </router-link>
                  <router-link
                    to="/printables/success-kits"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Success Kits</h6>
                  </router-link>
                </div>
              </nav>
            </div>
            <div data-hover="1" class="nav-dropdown w-dropdown" role="menu">
              <div
                class="nav-link w-dropdown-toggle"
                @mouseover="extrasDD = true"
                @mouseleave="extrasDD = false"
                style="outline: none"
              >
                <div class="row no-wrap">
                  <div>Extras</div>
                  <i class="fas fa-caret-down dropdown-caret"></i>
                </div>
              </div>
              <nav
                v-show="extrasDD"
                class="dropdown-list w-dropdown-list"
                id="w-dropdown-toggle-0"
                style="margin-top: -5px"
              >
                <div
                  class="dropdown-menu"
                  @mouseover="extrasDD = true"
                  @mouseleave="extrasDD = false"
                >
                  <a href="/video-scripts" class="dropdown-link w-dropdown-link"
                    >Video Scripts</a
                  >
                  <a href="/blogs" class="dropdown-link w-dropdown-link"
                    >Blogs</a
                  >
                  <router-link
                    to="/videos"
                    class="dropdown-link w-dropdown-link"
                    >Videos</router-link
                  >
                  <router-link
                    to="/listing-videos"
                    class="dropdown-link w-dropdown-link"
                    >Listing Videos</router-link
                  >
                  <router-link
                    to="/extras/email-signatures"
                    class="dropdown-link w-dropdown-link"
                    >Email Signatures</router-link
                  >
                  <router-link
                    to="/extras/highlight-covers"
                    class="dropdown-link w-dropdown-link"
                    >Highlight Covers</router-link
                  >
                  <router-link
                    to="/extras/instagram-stories"
                    class="dropdown-link w-dropdown-link"
                    >Instagram Stories</router-link
                  >
                  <router-link
                    to="/extras/logos"
                    class="dropdown-link w-dropdown-link"
                    >Logos</router-link
                  >
                   <router-link
                    to="/listing-generator"
                    class="dropdown-link w-dropdown-link"
                    >Listing Generator</router-link
                  >
                </div>
              </nav>
            </div>
            <div
              data-delay="0"
              data-hover="1"
              class="nav-dropdown w-dropdown"
              role="menu"
            >
              <div
                class="nav-link w-dropdown-toggle"
                @mouseover="ractive = true"
                @mouseleave="ractive = false"
                style="outline: none"
              >
                <div class="row no-wrap">
                  <div>Resources</div>
                  <i class="fas fa-caret-down dropdown-caret"></i>
                </div>
              </div>
              <nav
                v-show="ractive"
                class="dropdown-list shadow w-dropdown-list"
                id="w-dropdown-toggle-1"
                style="margin-top: -5px"
              >
                <div
                  class="dropdown-grid"
                  @mouseover="ractive = true"
                  @mouseleave="ractive = false"
                >
                  <a
                    v-if="!whiteLabel"
                    href="https://the-agent-nest-1.getrewardful.com/signup"
                    target="_blank"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Refer A Friend</h6>
                    <div class="text-small">
                      For every person you refer, get $15.
                    </div>
                  </a>
                  <a
                    v-if="!whiteLabel"
                    href="https://theagentnest.helpcenter.io"
                    target="_blank"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Help Center</h6>
                    <div class="text-small">
                      This is PASSWORD PROTECTED. The password is "Realtor1".
                      Learn about how to use the program, how to create facebook
                      ads, create pages, and more.
                    </div>
                  </a>
                  <!-- <a href="/ad-of-the-month" class="dropdown-link-block w-inline-block">
                    <h6 class="h6-small dropdown-heading">Facebook Ad</h6>
                    <div class="text-small">Your facebook ad of the month!</div>
                  </router-link>-->
                  <router-link
                    to="/resources/stock-photos"
                    class="dropdown-link-block w-inline-block"
                  >
                    <h6 class="h6-small dropdown-heading">Stock Photos</h6>
                    <div class="text-small">Download and Post.</div>
                  </router-link>
                </div>
              </nav>
            </div>
          </div>
          <button
            v-if="superAdmin"
            v-on:click="impersonateForm()"
            class="nav-link w-nav-link"
          >
            Impersonate
          </button>
        </nav>

        <div class="nav-menu-button-wrapper">
          <div class="nav-functions">
            <div class="modal-container">
              <div class="modal-button-wrapper">
                <div class="button bg-white search-button">
                  <div class="row profile-container">
                    <Profile />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-button w-nav-button" v-on:click="displayMobileNav">
            <i class="fas fa-bars fa-lg"></i>
          </div>
          <div class="w-nav-overlay" id="nav-mobile" style="height: 14578.8px">
            <nav
              role="navigation"
              class="nav-menu-wrapper w-nav-menu w--nav-menu-open"
              style="
                transform: translateY(0px) translateX(0px);
                transition: transform 400ms ease 0s;
              "
            >
              <div class="nav-links nav-links-2" id="nav-links-mobile"></div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Profile.vue";
export default {
  data() {
    return {
      smactive: false,
      ractive: false,
      pactive: false,
      etemplates: false,
      mobileOpen: false,
      extrasDD: false,
      logo: process.env.VUE_APP_LOGO,
      scheduledLink: "/upgrade",
      scheduledPosting: false,
    };
  },
  computed: {
    showSchedule: function () {
      if (process.env.VUE_APP_SCHEDULED_POSTING == "false") return false;
      return true;
    },
    whiteLabel: function () {
      if (process.env.VUE_APP_WHITELABEL == "true") return true;
      return false;
    },
    superAdmin: function () {
      return this.$auth.user["https://theagentnest.com/super_admin"];
    },
  },
  components: {
    Profile,
  },
  mounted() {
    if (this.$auth.user["https://theagentnest.com/scheduled_sending"] != true) {
      this.scheduledLink = "/upgrade";
    } else {
      this.scheduledPosting = true;
      var safeEmail = encodeURIComponent(this.$auth.user.email);
      var hmac = this.$auth.user["https://theagentnest.com/hmac"];
      var baseUrl = process.env.VUE_APP_API_URL;
      this.scheduledLink =
        baseUrl +
        "/api/user/schedule-redirect?email=" +
        safeEmail +
        "&hmac=" +
        hmac;
    }
  },
  methods: {
    impersonateForm() {
      this.$swal.fire({
        title: "Enter User Details",
        html:
          '<input id="swal-input1" placeholder="Email" class="swal2-input">' +
          '<input id="swal-input2" placeholder="HMAC" class="swal2-input">',
        focusConfirm: false,
        preConfirm: () => {
          var email = document.getElementById("swal-input1").value;
          var hmac = document.getElementById("swal-input2").value;
          var safeEmail = encodeURIComponent(email);
          var baseUrl = process.env.VUE_APP_API_URL;
          this.scheduledLink =
            baseUrl +
            "/api/user/schedule-redirect?email=" +
            safeEmail +
            "&hmac=" +
            hmac;
        },
      });
    },
    displayMobileNav() {
      if (this.mobileOpen == false) {
        //Get Current Links and clone them
        var links = document.getElementById("nav-links-desktop");
        var clonedLinks = links.cloneNode(true);
        clonedLinks.removeAttribute("class");
        clonedLinks.removeAttribute("id");
        clonedLinks.setAttribute("class", "mobile-links");
        document.getElementById("nav-links-mobile").appendChild(clonedLinks);
        //Remove weird formatting from desktop
        var elems = document.querySelectorAll(".nav-links-2  .row");
        console.log(elems);
        [].forEach.call(elems, function (el) {
          el.classList.remove("row");
        });

        //Add in the profile links
        var profileLinks = document.getElementById("profile-links");
        var clonedProfileLinks = profileLinks.cloneNode(true);
        clonedProfileLinks.classList.add("profile-mobile");
        clonedLinks.appendChild(clonedProfileLinks);

        //Show nav, and add event listener for sub dropdowns
        var nav = document.querySelector("#nav-mobile");
        nav.setAttribute("style", "display:inline-block; height: 15000px");
        [...document.querySelectorAll(".w-dropdown-toggle")].forEach(function (
          item
        ) {
          item.addEventListener("click", function () {
            if (!this.classList.contains("open")) {
              this.nextSibling.setAttribute("style", "display:block");
              this.classList.add("open");
            } else {
              this.nextSibling.setAttribute("style", "display:none");
              this.classList.remove("open");
            }
          });
        });

        this.mobileOpen = true;
      } else {
        this.mobileOpen = false;
        const parent = document.getElementById("nav-links-mobile");
        while (parent.firstChild) {
          parent.firstChild.remove();
        }
        var navy = document.querySelector("#nav-mobile");
        navy.setAttribute("style", "display:none");
      }
    },
  },
};
</script>

<style>
.navigation-container {
  position: relative;
  z-index: 99;
}

.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.nav-link {
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 12px;
  white-space: nowrap;
}

.nav-link:hover {
  text-decoration: none;
}

.nav-link.w--current {
  color: #068466;
}

.avatar-container {
  position: relative;
  display: block;
}

.avatar-indicator {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 25%;
  height: 25%;
  border: 2px solid #fff;
  border-radius: 50%;
}

.nav-menu-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-menu-wrapper.justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav-links.nav-links-1 {
  margin-left: 36px;
}

.nav-functions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-menu-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-dropdown {
  margin-right: 0px;
  margin-left: 0px;
}
.mobile-links {
  width: 100%;
}
.mobile-links .nav-link {
  font-size: 36px;
  border-bottom: 1px solid;
  margin-bottom: 14px;
  width: 100%;
  text-align: center;
  padding: 10px;
}
.profile-mobile a {
  text-align: center;
  font-size: 24px;
}
.profile-container {
  display: none;
}
@media only screen and (min-width: 850px) {
  #nav-mobile {
    display: none !important;
  }
  .profile-container {
    display: flex;
  }
}
</style>
