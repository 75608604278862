<template>
  <v-app id="app">
    <router-view />
    <ReferAfriend />
  </v-app>
</template>
<script>
import ReferAfriend from "@/components/ReferAFriend.vue";

export default {
  components: {
    ReferAfriend,
  },
};
</script>
<style>
a.button {
  color: white !important;
}
a.nav-link,
a.dropdown-link-block,
a.dropdown-link {
  color: black !important;
}
</style>
