import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://api.buttercms.com/v2/content/social_media_posts',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const apiKey = "&auth_token=60ff148b955970a8f326d597e2f33d0e65cd5bc1";
export default {
  getMonth(year) {
    //remove "&cache=true" for better performance.
    return apiClient.get('?order=date&fields.is_featured_on_monthly_calendar=true&fields.year.slug='+year+apiKey)
    //Use this cache buster if buttercms isnt working
    //return apiClient.get('?order=date&fields.is_featured_on_monthly_calendar=true'+apiKey+"&cache=true")
  },
  getPosts(type){
    return apiClient.get('?order=date&fields.type='+type+apiKey)
  },
  getSecondaryPosts(type){
    return apiClient.get('?order=date&fields.type2='+type+apiKey)
  },
  getToday(date){
    return apiClient.get(date)
  }
}