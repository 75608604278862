import router from '../router';
import { GlobalConfig } from '../configs';
import axios from 'axios';
import store from '../store';
import { GetterTypes } from '../store/types/getter-types';

const getAuthToken = () => store.getters[GetterTypes.AUTH_TOKEN]();

const config = {
  baseURL: GlobalConfig.appApiUrl,
  timeout: 1000 * 20,
};

const authInterceptor = (config) => {
  const token = getAuthToken();
  if (token) config.headers['Authorization'] = 'Bearer ' + token;
  return config;
};

const errorInterceptor = (error) => {
  if (!error.response) {
    console.warn('Network/Server error');
    return Promise.reject(error);
  }

  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message);
      break;
    case 403:
      break;
    case 401:
      //router.push('/login');
      break;

    default:
      console.error(error.response.status, error.message);
      console.error('Server Error');
  }
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      break;
    default:
  }
  return response;
};

const httpClient = axios.create(config);
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
export default httpClient;
