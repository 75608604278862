import { BILLING_ENDPOINT, TEAM_ENDPOINT } from './endpoints';
import httpClient from './httpClient';

class BillingService {
  async getPortal() {
    return (await httpClient.post(BILLING_ENDPOINT+"/portal")).data;
  }
  async getUpgradePortal() {
    return (await httpClient.post(BILLING_ENDPOINT+"/upgrade")).data;
  }
  async getByUserId(userId) {
    return (
      await httpClient.get(TEAM_ENDPOINT, {
        params: { userId },
      })
    ).data;
  }
}

export const billingService = new BillingService();
export default billingService;
