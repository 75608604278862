<template>
  <div>
    <div v-if="!$auth.loading">
      <div
        v-if="$auth.isAuthenticated"
        data-hover="1"
        class="nav-dropdown w-dropdown"
        role="menu"
      >
        <div
          class="nav-link w-dropdown-toggle"
          @mouseover="smactive = true"
          @mouseleave="smactive = false"
          style="outline: none"
        >
          <div class="row no-wrap align-center">
            <div>
              <div>
                <img
                  v-if="$auth.user.picture != null"
                  class="profile-pic"
                  :src="$auth.user.picture"
                />
                <i v-else class="far fa-user"></i>
                {{ $auth.user.name }}
              </div>
            </div>
            <i
              :style="$auth.user.picture ? 'margin-left: 35px' : ''"
              class="fas fa-caret-down dropdown-caret"
            ></i>
          </div>
        </div>
        <nav
          v-show="smactive"
          class="dropdown-list w-dropdown-list"
          id="w-dropdown-toggle-0"
        >
          <div
            class="dropdown-menu"
            id="profile-links"
            @mouseover="smactive = true"
            @mouseleave="smactive = false"
          >
            <a
              v-if="!whiteLabel"
              class="dropdown-link w-dropdown-link"
              v-on:click="openBillingPortal()"
              >Billing</a
            >
            <a href="/profile" class="dropdown-link w-dropdown-link">Profile</a>
            <a href="/team" class="dropdown-link w-dropdown-link">Team</a>
            <!-- <a href="/members" class="dropdown-link w-dropdown-link">Members</a> -->
            <a href="/logout" class="dropdown-link w-dropdown-link">Log out</a>
          </div>
        </nav>
      </div>
      <!-- show login when not authenticated -->
      <div v-if="!$auth.isAuthenticated" @click="login">Log in</div>
      <!-- show logout when authenticated -->
    </div>
  </div>
</template>

<script>
import { billingService } from "@/services";
export default {
  data() {
    return {
      smactive: false,
    };
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      //Not sure why this doesnt work on mobile
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    openBillingPortal() {
      billingService
        .getPortal()
        .then((data) => {
          console.log(data);
          window.open(data, "_blank");
        })
        .catch((error) => {
          this.$swal("Error", error.response.data);
          this.overlay = false;
        });
    },
  },
  computed: {
    whiteLabel: function () {
      if (process.env.VUE_APP_WHITELABEL == "true") return true;
      return false;
    },
  },
};
</script>

<style scoped>
.profile-pic {
  height: 30px;
  border-radius: 50%;
}
.dropdown-caret {
  margin-top: 0;
  margin-left: 8px;
}
.dropdown-list {
  margin-left: 34px;
  margin-top: -12px;
}
</style>
