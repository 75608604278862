import { ActionTypes } from '../types/action-types';
import { MutationTypes } from '../types/mutation-types';
import teamService from '../../services/teamService';

const state = {
  teams: undefined,
};

const mutations = {
  [MutationTypes.SET_TEAMS](state, teams) {
    state.teams = teams;
  },
};

const actions = {
  [ActionTypes.LOAD_TEAMS]({ commit, state }) {
    return new Promise((resolve, reject) => {
      teamService
        .getAll()
        .then((teams) => {
          commit(MutationTypes.SET_TEAMS, teams);
          resolve(teams);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ActionTypes.LOAD_CURRENT_TEAM]({ commit, state }) {
    return new Promise((resolve, reject) => {
      teamService
        .getCurrent()
        .then((team) => {
          commit(MutationTypes.SET_TEAM, team);
          resolve(team);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {};

const teamModule = {
  namespaced: false,
  actions,
  mutations,
  state,
  getters,
};

export default teamModule;
