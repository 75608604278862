import { TEAM_ENDPOINT } from './endpoints';
import httpClient from './httpClient';

class TeamService {
  async signup(signup) {
    return (await httpClient.post(TEAM_ENDPOINT+"/sign-up", signup)).data;
  }
  async create(team) {
    return (await httpClient.post(TEAM_ENDPOINT, team)).data;
  }
  async update(team) {
    return (await httpClient.put(TEAM_ENDPOINT, team)).data;
  }
  async patch(team) {
    return (await httpClient.patch(TEAM_ENDPOINT, team)).data;
  }
  async getById(teamId) {
    return (await httpClient.get(`${TEAM_ENDPOINT}/${teamId}`)).data;
  }
  async getCurrent() {
    return (await httpClient.get(`${TEAM_ENDPOINT}/${'current'}`)).data;
  }
  async deleteById(teamId) {
    return (await httpClient.delete(`${TEAM_ENDPOINT}/${teamId}`)).data;
  }
  async getAll() {
    return (await httpClient.get(TEAM_ENDPOINT)).data;
  }
  async getByUserId(userId) {
    return (
      await httpClient.get(TEAM_ENDPOINT, {
        params: { userId },
      })
    ).data;
  }
}

export const teamService = new TeamService();
export default teamService;
