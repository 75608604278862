import { AI_ENDPOINT } from './endpoints';
import httpClient from './httpClient';

class AIService {
  async generate(post) {
    return (await httpClient.post(AI_ENDPOINT+"/generate", post)).data;
  }
  async generateListing(listing) {
    return (await httpClient.post(AI_ENDPOINT+"/listing-generator", listing)).data;
  }
  async getByUserId(userId) {
    return (
      await httpClient.get(AI_ENDPOINT, {
        params: { userId },
      })
    ).data;
  }
}

export const aiService = new AIService();
export default aiService;
