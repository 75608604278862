import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions';
import modules from './modules';
import { mutations } from './mutations';
import { GetterTypes } from './types/getter-types';

Vue.use(Vuex);

const state = {
  currentTeam: undefined,
  currentUser: undefined,
  token: undefined,
};

const getters = {
  [GetterTypes.IS_LOGGED_IN]: (state) => !!state.currentUser,
  [GetterTypes.AUTH_TOKEN]: (state) => () => state.token,
  [GetterTypes.IS_ADMIN]: (state) => !!(state.currentUser && state.currentUser.role === 1), 
  [GetterTypes.IS_SUPER_ADMIN]: (state) => !!(state.currentUser && state.currentUser.role === 2),
};

export default new Vuex.Store({
  modules,
  state,
  mutations,
  actions,
  getters,
});

