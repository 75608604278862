<template>
  <div>
    <div class="section bg-gray-4">
      <div class="container">
        <div v-if="loading" class="w-layout-grid grid-quarters cards-grid">
          <CalendarPost
            v-for="post in posts"
            :key="post.date"
            :post="post"
            :hashtags="hashTags"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarService from "@/services/calendarService.js";
import CalendarPost from "@/components/CalendarPost.vue";
import ActionTypes from "@/store/types/action-types.js";
import moment from "moment";
import { teamService } from "@/services";

export default {
  props: {
    selectedDate: Number,
  },
  data() {
    return {
      posts: [],
      allPosts: [],
      yearInMemory: "", //Posts are filtered by year from ButterCMS. This defines which year is currently in Memory
      hashTags: "",
      localAuth: [],
      loading: false,
    };
  },
  components: {
    CalendarPost,
  },
  async created() {
    var formatedYear = moment(this.selectedDate).format("YYYY");
    this.updateCalendar(formatedYear);
    this.yearInMemory = formatedYear;

    //Waits for authentication and then loads all team to populate hashtags
    if (this.$auth.isAuthenticated) {
      teamService.getCurrent().then((data) => {
        this.team = data;
        if(data!=null)
        {
          if(data.hashTags!=null)
          {
            this.hashTags = this.hashTags + " " + data.hashTags;
          }
        }
      
        //Hashtags are loaded, now let's load the calendar
        this.loading = true;
      }).catch((error) => {
        //if the backend is down, still load the calendar
        this.loading = true;
      });
    }
  },
  watch: {
    selectedDate: {
      // the callback will be called immediately after the start of the observation
      immediate: false,
      handler(val) {
        var formatedYear = moment(val).format("YYYY");
        //update the posts in Memory from butter if different
        if (this.formatedYear != this.yearInMemory) {
          console.log(formatedYear);
          if (this.$auth.user["https://theagentnest.com/super_admin"]) {
            this.updateCalendar(formatedYear);
          } else {
            // if (formatedYear != "2021" && formatedYear != "2020") {
            //   this.updateCalendar(formatedYear);
            // }
             this.updateCalendar(formatedYear);
          }
        }
        // do your stuff
        var filteredPosts = [];
        var calendarDate = val;
        this.allPosts.forEach(function (post) {
          if (moment(calendarDate).isSame(post.date, "month")) {
            filteredPosts.push(post);
          }
        });
        this.posts = filteredPosts;
        this.yearInMemory = formatedYear;
      },
    },
  },
  methods: {
    updateCalendar(year) {
      CalendarService.getMonth(year)
        .then((response) => {
          this.allPosts = response.data.data.social_media_posts;
          var filteredPosts = [];
          var todaysPost = [];
          var calendarDate = this.selectedDate;
          var today = new Date();
          response.data.data.social_media_posts.forEach(function (post) {
            if (moment(calendarDate).isSame(post.date, "month")) {
              filteredPosts.push(post);
            }
            if (moment(today).isSame(post.date, "d")) {
              todaysPost.push(post);
            }
          });
          //filteredPosts = response.data.data.social_media_posts;
          this.posts = filteredPosts;
          this.$root.$emit("eventing", todaysPost);
        })
        .catch((error) => {
          console.log("there was an error getting the calendar", error);
        });
    },
  },
};
</script>

<style scoped>
</style>