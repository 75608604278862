<template>
  <div>
    <div class="card no-border">
      <a v-bind:href="download()" class="w-inline-block">
        <h6>{{ post.date | formatDate }}</h6>
        <img
          v-if="!this.post.animated"
          v-bind:src="this.compressedPhoto"
          @error="imageLoadError"
          alt
          class="card-image-head"
        />
        <video
          v-else
          v-bind:src="this.post.media"
          alt
          class="card-video-head"
          autoplay
          muted
          loop
          playsinline
        ></video>
      </a>
      <br />
      <div class="card-body">
        <div class="post-description-container">
          <textarea
            v-model="descriptionFilterBranding"
            ref="postDescription"
            class="form-input text-area-input w-input post-description"
          ></textarea>
          <div class="icon-row">
            <div class="icon-container">
              <span class="ai-icon" v-on:click="rewrite"
                ><i class="fas fa-magic"></i
              ></span>
              <span class="copy-text" v-on:click="copy"
                ><i class="fas fa-copy"></i
              ></span>
            </div>
          </div>
        </div>
        <a
          v-bind:href="download()"
          class="button w-inline-block post-download"
          download
        >
          <div>
            Download
            <i class="fas fa-download"></i>
          </div>
        </a>
        <a
          v-bind:href="post.editable_link"
          class="button w-inline-block post-edit"
          download
          v-on:click="track"
          target="_blank"
          v-if="post.editable_link !== null && post.editable_link !== ''"
        >
          <div>
            Edit
            <i class="fas fa-edit"></i>
          </div>
        </a>

        <ShareButton :post="post" />
        <a
          v-bind:href="butterLink"
          class="button w-inline-block post-find"
          target="_blank"
          v-if="superAdmin == true"
        >
          <div>
            Find
            <i class="fas fa-hand-point-left"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ShareButton from "@/components/ScheduleButton.vue";
import { aiService } from "@/services";


export default {
  props: {
    post: Object,
    hashtags: String
  },
  components: {
    ShareButton,
  },
  computed: {
    day: function () {
      return "hi";
    },
    compressedPhoto: function () {
      if (this.post.animated) return this.post.media;
      return this.resizePhoto(this.post.media, 400);
    },
    superAdmin: function () {
      return this.$auth.user["https://theagentnest.com/super_admin"];
    },
    butterLink: function () {
      return (
        "https://buttercms.com/content/workspace/12628/list/social_media_posts/entry/" +
        this.post.meta.id
      );
    },
    descriptionFilterBranding: {
      
      get: function () {
        //filters out agent nest branding for white label
        if (
          this.post.description != null &&
          process.env.VUE_APP_WHITELABEL == "true"
        ) {
          var post = this.post.description.replaceAll("#theagentnest", "");
          return post.replaceAll("#agentnest", "");
        }
     
        //Apply universal hashtags

        return this.post.description +" "+this.hashtags;
      },
      set: function (newValue) {
        //branding may leak out on branding. Double check here on Whitelabel clients
        this.post.description = newValue;
        return newValue;
      },
    },
  },
  // mounted()
  // {
  //       teamService.getCurrent().then((data) => {
  //         console.log(data);
  //         this.team = data;
  //        this.descriptionFilterBranding = this.descriptionFilterBranding +" "+data.hashTags
  //       });
         
  // },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    },
    imageLoadError() {
      event.target.src = this.post.media;
    },
    rewrite() {
      console.log(this.post);
      var post = {};
      post.Title = this.post.post_title;
      post.Content = this.post.description;
      console.log(post);
      this.descriptionFilterBranding = "Rewriting using AI...";
      aiService.generate(post).then((data) => {
        var post = data;
        this.descriptionFilterBranding = post;
      });
    },
    copy() {
      console.log(this.post);
      this.copyText(this.$refs.postDescription); //Custom mixin. Ref is defined in the element e.g. <div ref="text">
      this.$swal.fire({
        title: "Text Copied to Clipboard",
        timer: 1200,
        icon: "success",
        toast: true,
      });
    },
    download: function () {
      var media = this.post.media;
      media = media.substring(26);
      var extension = ".png";
      if (this.post.animated) {
        extension = ".mp4";
      }
      var safeTitle = encodeURI(this.post.post_title + extension);
      var url =
        "https://fs.buttercms.com/content=t:attachment,f:%22" +
        safeTitle +
        "%22/" +
        media;
      return url;
    },
    track() {
      // eslint-disable-next-line no-undef
      mixpanel.track("Downloaded Post", {
        Title: this.post_title,
        Calendar: true,
        Category: this.post.type,
      });
    },
  },
};
</script>

<style  scoped>
.post-edit {
  margin-left: 15px;
  padding: 5px 10px;
  cursor: pointer;
}
.post-find {
  margin-top: -40px;
  margin-right: 20px;
  padding: 5px 10px;
  cursor: pointer;
  float: right;
}
.post-description {
  resize: none;
  width: 110%;
  margin-left: -15px;
}

.post-description-container {
  margin-bottom: 15px;
}
.card-video-head {
  width: 280px;
}
.icon-row {
  width: 100%;
}
.icon-container {
  width: 50px;
  margin-top: 4px;
  margin-left: auto;
  margin-right: 0;
}
.ai-icon {
  cursor: pointer;
  padding: 5px;
}
.copy-text {
  cursor: pointer;
  padding: 5px;
}
</style>