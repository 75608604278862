import { USER_ENDPOINT } from "./endpoints";
import httpClient from "./httpClient";

class UserService {
  async authenticate(user) {
    return (await httpClient.post(USER_ENDPOINT, user)).data;
  }
  async create(user) {
    return (await httpClient.post(USER_ENDPOINT, user)).data;
  }
  async update(user) {
    return (await httpClient.put(USER_ENDPOINT, user)).data;
  }
  async patch(user) {
    const userId = user.id;
    return (await httpClient.patch(`${USER_ENDPOINT}/${userId}`, user)).data;
  }
  async invite(user) {
    return (await httpClient.post(`${USER_ENDPOINT}/${"invite"}`, user)).data;
  }
  async accept(user) {
    return (await httpClient.post(`${USER_ENDPOINT}/${"accept"}`, user)).data;
  }
  async getById(userId) {
    return (await httpClient.get(`${USER_ENDPOINT}/${userId}`)).data;
  }
  async deleteById(userId) {
    return (await httpClient.delete(`${USER_ENDPOINT}/${userId}`)).data;
  }
  async getAll() {
    return (await httpClient.get(USER_ENDPOINT)).data;
  }

  async getMembers() {
    return (await httpClient.get(`${USER_ENDPOINT}/${'members'}`)).data;
    // return dummy members
    // const member = {
    //   id: 0,
    //   firstName: "John",
    //   lastName: "Doe",
    //   createdAt: "2021-08-27T09:29:22.795Z",
    //   updatedAt: "2021-08-27T09:29:22.795Z",
    //   auth0UserId: "string",
    //   teamId: 0,
    //   email: "string",
    //   phone: "string",
    //   type: 0,
    //   status: -1,
    //   lastLoginAt: "2021-08-27T09:29:22.795Z",
    //   invitationSentAt: "2021-08-27T09:29:22.795Z",
    // };

    // return [
    //   {...member, id: '345f51', email: '1@gmail.com'},
    //   {...member, id: '345f52', email: '2@gmail.com'},
    //   {...member, id: '345f53', email: '3@gmail.com'},
    //   {...member, id: '345f54', email: '4@gmail.com'},
    //   {...member, id: '345f55', email: '5@gmail.com'},
    //   {...member, id: '345f56', email: '6@gmail.com'},
    //   {...member, id: '345f57', email: '7@gmail.com'},
    //   {...member, id: '345f58', email: '8@gmail.com'},
    //   {...member, id: '345f59', email: '9@gmail.com'}
    // ]
  }
}

export const userService = new UserService();
export default userService;
