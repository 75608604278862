export const MutationTypes = {
  SET_USER: 'SET_USER',
  SET_USERS: 'SET_USERS',
  SET_TEAM: 'SET_TEAM',
  SET_TEAMS: 'SET_TEAMS',
  SET_CURRENT_TEAM: 'SET_CURRENT_TEAM',
  SET_CURRENT_USER: 'SET_CURRENT_USER',

  SET_TOKEN: 'SET_TOKEN',
};
