import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://api.buttercms.com/v2/pages/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const apiKey = "auth_token=60ff148b955970a8f326d597e2f33d0e65cd5bc1";
export default {
  getPage(slug) {
    return apiClient.get("*/"+slug+"?"+apiKey)
  }
}