<template>
  <div v-if="enabled">
    <a
      v-if="$auth.user['https://theagentnest.com/scheduled_sending'] == true"
      href="javascript:void(0)"
      class="compose_share button w-inline-block post-schedule"
      :data-text="post.description"
      :data-picture-url="post.media"
    >
      Schedule
      <i class="fas fa-clock"></i>
    </a>
    <button
      v-else
      v-on:click="upsell"
      class="button w-inline-block post-schedule"
    >
      Schedule
      <i class="fas fa-clock"></i>
    </button>
  </div>
</template>

<script>
import { billingService } from "@/services";
export default {
  data: () => ({
    canvaAPIKey: process.env.VUE_APP_CANVA_API,
  }),
  props: {
    post: Object,
  },
  mounted() {
    window.sendible.start();
  },
  computed: {
    enabled: function () {
      if (process.env.VUE_APP_SCHEDULED_POSTING == "false") return false;
      return true;
    },
  },
  methods: {
    upsell: function () {
      this.$swal
        .fire({
          icon: "info",
          title: "Upgrade to schedule your posts ahead of time.",
          text: "Connect your Facebook, Instagram, Twitter, Linked-In, and Google Business.",
        })
        .then((result) => {
          if (result.value) {
            //this.$router.push("upgrade");
            billingService
              .getUpgradePortal()
              .then((data) => {
                console.log(data);
                  window.open(data, "_blank");
              })
              .catch((error) => {
                this.$swal("Error", error.response.data);
                this.overlay = false;
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.post-schedule {
  margin-top: 15px;
  font-size: 16px;
  padding: 6px 10px;
}
</style>