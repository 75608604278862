<template>
  <div
    v-if="show"
    id="daily-tip-container"
    :style="{ 'background-color': this.background_color }"
  >
    <div v-html="banner_content"></div>
    <div id="dismiss" v-on:click="close()">
      <span><i class="fas fa-times-circle"></i></span>
    </div>
  </div>
</template>
<script>
import PageService from "@/services/pageService.js";
import moment from "moment";
export default {
  props: {},
  data() {
    return {
      show: true,
      expire_date: null,
      banner_content: null,
      background_color: null,
    };
  },
  computed: {},
  methods: {
    close: function () {
      this.show = false;
    },
  },
  created() {
    PageService.getPage("announcement-banner")
      .then((response) => {
        var content = response.data.data.fields;
        this.expire_date = content.expire_date;
        this.banner_content = content.text_content;

        var momentDate = moment(this.expire_date);
        var today = new Date();
        if (momentDate.diff(today) < 0) {
          this.show = false;
        } else {
          if (
            content.background_color != null &&
            content.background_color.length > 1
          ) {
            this.background_color = content.background_color;
          } else {
            this.background_color = "#faf2f0";
          }
        }
      })
      .catch((error) => {
        this.show = false;
        console.log("there was an error getting the page", error);
      });
  },
};
</script>
    
    <style scoped>
#daily-tip-container {
  width: 100%;
  top: 0px;
  z-index: 4;
  padding-top: 25px;
  padding-right: 90px;
  padding-bottom: 10px;
  padding-left: 90px;
}

#daily-tip-container p {
  font-size: 20px;
}
#dismiss {
  cursor: pointer;
  right: 35px;
  top: 448px;
  z-index: 101;
  position: absolute;
}
@media only screen and (min-width: 850px) {
  #daily-tip-container {
    position: sticky;
  }
  #dismiss
  {
    top:20px;
  }
}
</style>