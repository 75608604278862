<template>
  <div>
    <div v-if="!$auth.loading">
      <div v-if="$auth.isAuthenticated">
        <div class="footer portal-footer-color">
          <div class="container">
            <div class="footer-2-header">
              <!-- <img
                src="images/agent-logo-h.png"
                width="117"
              /> -->
              <!--Shane please fix this later, it doesn't work on mobile-->
            </div>
            <!-- <div>
              <div class="footer-2-subscribe-text refer-text">Invite a friend, get a free month!</div>
              <div class="w-form">
                <div id="email-form" class="subscribe-form">
                  <input
                    type="email"
                    class="form-input w-input"
                    maxlength="256"
                    name="email-2"
                    data-name="Email 2"
                    placeholder="Friend's Email Address"
                    id="email-2"
                    required
                    v-model="email"
                  />
                  <button
                    v-on:click="refer"
                    class="send button form-search-button w-button"
                  >Send Invite</button>
                </div>
              </div>
            </div> -->
            <div class="footer-2-right">
              <img v-bind:src="logo" width="200" />
              <a :href="'mailto:' + support">{{ support }}</a>
              <div class="dallas">{{ location }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      support: process.env.VUE_APP_SUPPORT_EMAIL,
      location: process.env.VUE_APP_LOCATION,
      logo: process.env.VUE_APP_FOOTER_LOGO,
      //logo:"https://morealty.blob.core.windows.net/public/social_agent_short_long.png"
    };
  },
  methods: {
    refer() {
      var currentUser = this.$auth.user.email;
      if (this.email == null || this.email == "") {
        this.$swal("Please enter an email address");
      } else {
        this.$swal(
          "Success",
          "Once your friend signs up, we will credit your account and your next month will be free!"
        );
        var userObj = {
          friend: this.email,
          user: currentUser,
        };
        var xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          "https://hooks.zapier.com/hooks/catch/1261564/ohu7ii7/",
          true
        );
        xhr.send(
          JSON.stringify({
            body: userObj,
          })
        );
      }
    },
  },
};
</script>

<style scoped>
a {
  color: white !important;
}
.send {
  width: 155px;
  border: 2px solid black;
}
.refer-text {
  color: black;
}
.dallas {
  color: black;
}
</style>
