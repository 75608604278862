<template>
  <div class="home">
    <!-- {{title}} -->

    <Navigation />
    <Announcement />
    <Featured />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>
          <i v-on:click="reverse" class="fas fa-arrow-left arrow"></i>
          {{ month }}
          <i
            v-on:click="advance"
            v-if="
              displayForward == true ||
              $auth.user['https://theagentnest.com/super_admin'] == true
            "
            class="fas fa-arrow-right arrow"
          ></i>
        </h1>
        <div class="text-large">Content Calendar</div>
      </div>
    </div>

    <Calendar :selectedDate="currentDate" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Featured from "@/components/FeaturedPostToday.vue";
import moment from "moment";
import Calendar from "@/components/Calendar.vue";
import Announcement from "@/components/AnnouncementBanner.vue";

export default {
  name: "home",
  components: {
    Navigation,
    Featured,
    Calendar,
    Announcement,
  },
  data() {
    return {
      currentDate: Date.now(),
      month: moment(Date.now()).format("MMMM YYYY"),
      title: process.env.VUE_APP_TITLE,
      displayForward: moment().date() > 25,
    };
  },

  methods: {
    advance: function () {
      var futureMonth = moment(this.currentDate).add(1, "M");
      var formatedMonth = moment(futureMonth).format("MMMM YYYY");
      this.month = formatedMonth;
      this.currentDate = futureMonth;
      this.displayForwardListener();
    },
    reverse: function () {
      var bornOnDate = new Date(2019, 11, 31);
      var pastMonth = moment(this.currentDate).subtract(1, "M");
      if (bornOnDate <= pastMonth) {
        var formatedMonth = moment(pastMonth).format("MMMM YYYY");
        this.month = formatedMonth;
        this.currentDate = pastMonth.toDate();
        this.displayForwardListener();
      }
    },
    displayForwardListener: function () {
      if (
        this.$auth &&
        this.$auth.user &&
        this.$auth.user["https://theagentnest.com/super_admin"] == true
      ) {
        this.displayForward = true;
      }
      //Show forward arrow on previous month, and on the 27th of each month
      if (moment(this.month).isSame(new Date(), "month")) {
        //Currently not working.
        if (moment().date() > 25) {
          this.displayForward = true;
        } else {
          this.displayForward = false;
        }
      } else {
        this.displayForward = true;
      }
    },
  },
};
</script>
